$(function () {

  window.msu = {};

  window.msu.form = ({

    init: function () {

      var _th = this;
      var fields = $('.form__field-input, .form__field-textarea');

      if (fields.length) {
        fields.each(function () {
          var _t = $(this);
          _t.val().trim() === '' ? _t.removeClass('not-empty') : _t.addClass('not-empty');
        });
        fields.on('blur keyup', function () {
          var _t = $(this);
          setTimeout(function () {
            _t.val().trim() === '' ? _t.removeClass('not-empty') : _t.addClass('not-empty');
          }, 50)
        });
      }

      if ($('.js-clear-form-field').length) {
        $('.js-clear-form-field').on('click', function () {
          if ($(this).siblings('.form__field-input').length) {
            $(this).siblings('.form__field-input')
              .val('')
              .removeClass('not-empty');
          }
          if ($(this).siblings('.form__field-textarea').length) {
            $(this).siblings('.form__field-textarea')
              .val('')
              .removeClass('not-empty');
          }
          return false;
        });
      }

      if ($('.js-phone').length) {
        $('.js-phone').mask('+7(999) 999-9999');
      }

      $('form').submit(function () {
        if (!_th.checkForm($(this)))
          return false;
      });
    },

    checkForm: function (form) {
      var checkResult = true;
      form.find('.error').removeClass('error');
      form.find('input, textarea, select').each(function () {
        if ($(this).data('req')) {
          switch ($(this).data('type')) {
            case 'tel':
              var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
              if (!re.test($(this).val())) {
                $(this).addClass('error');
                checkResult = false;
              }
              break;
            case 'email':
              var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
              if (!re.test($(this).val())) {
                $(this).addClass('error');
                checkResult = false;
              }
              break;
            case 'agreement':
              if (!$(this).is(':checked')) {
                $(this).parents('.checkbox').addClass('error');
                checkResult = false;
              }
              break;
            default:
              if ($.trim($(this).val()) === '') {
                $(this).addClass('error');
                checkResult = false;
              }
              break;
          }
        }
      });
      return checkResult;
    }

  }).init();

  window.msu.obj = {

    map: function () {

      let map = $('.js-map'),
        coords = map.data('coords').split(',');

      ymaps.ready(function () {

        var myMap = new ymaps.Map("yaMap", {
          center: [coords[0], coords[1]],
          zoom: map.data('zoom') || 16,
          controls: []
        });

        myMap.controls.add('zoomControl', {
          size: 'small'
        });

        myMap.behaviors.disable('scrollZoom');
        let myPlacemark = new ymaps.Placemark(coords, {}, {
          iconLayout: 'default#image',
          iconImageHref: '/static/img/pin.png',
          iconImageSize: [20, 28]
        });
        myMap.geoObjects.add(myPlacemark);

      });

      return;

    },

    init: function () {

      var _self = this;

      if ($('.js-map').length) this.map();

      if ($('.js-burger').length) {
        $('.js-burger').on('click', function () {
          $(this).toggleClass('active');
          $('.nav').fadeToggle(350);
          $('body').toggleClass('open-menu');
          return false;
        })
      }

      if ($('.js-announce-swiper').length) {
        var announceSwiper = new Swiper('.js-announce-swiper', {
          loop: true,
          speed: 750,
          slidesPerView: 'auto',
          loopedSlides: 2,
          spaceBetween: 29,
          watchSlidesProgress: true,
          watchSlidesVisibility: true,
          allowTouchMove: false,
          navigation: {
            nextEl: '.announce .swiper-button-next',
            prevEl: '.announce .swiper-button-prev'
          },
          breakpoints: {
            320: {
              slidesPerView: 1,
              loopedSlides: null,
              spaceBetween: 0,
              allowTouchMove: true,
            },
            992: {
              slidesPerView: 'auto',
              loopedSlides: 2,
              spaceBetween: 29,
              allowTouchMove: false,
            }
          }
        });
      }

      if ($('.js-projects-swiper').length) {
        var projectsSwiper = new Swiper('.js-projects-swiper', {
          loop: false,
          autoHeight: true,
          speed: 750,
          slidesPerView: 1,
          spaceBetween: 29,
          allowTouchMove: false,
          navigation: {
            nextEl: '.projects .projects__bottom .swiper-button-next',
            prevEl: '.projects .projects__bottom .swiper-button-prev'
          }
        });
      }

      if ($('.js-projects-swiper-mobile').length) {
        var projectsSwiperMobile = new Swiper('.js-projects-swiper-mobile', {
          loop: false,
          autoHeight: true,
          speed: 750,
          slidesPerView: 1,
          spaceBetween: 29,
          navigation: {
            nextEl: '.projects .swiper-button-next',
            prevEl: '.projects .swiper-button-prev'
          }
        });
      }

      if ($('.js-gallery-swiper').length) {
        var gallerySwiper = new Swiper('.js-gallery-swiper', {
          loop: false,
          autoHeight: true,
          speed: 750,
          slidesPerView: 1,
          spaceBetween: 30,
          watchSlidesProgress: true,
          watchSlidesVisibility: true,
          navigation: {
            nextEl: '.gallery .swiper-button-next',
            prevEl: '.gallery .swiper-button-prev'
          }
        });
      }

      if ($('.js-tabs-btn').length) {
        $('.js-tabs-btn').on('click', function () {
          var _t = $(this),
            _tData = _t.data('tabs-btn'),
            _tParents = _t.parents('.tabs'),
            allBtns = _tParents.find('.tabs__controls-btn'),
            allTabs = _tParents.find('.tabs__list-item');
          if (!_t.hasClass('tabs__controls-btn--active') && _tParents.find('.tabs__list-item[data-tabs-item="' + _tData + '"]').length) {
            allBtns.removeClass('tabs__controls-btn--active');
            _t.addClass('tabs__controls-btn--active');
            allTabs.fadeOut(300, function () {
              $(this).removeClass('tabs__list-item--active');
            });
            setTimeout(function () {
              _tParents.find('.tabs__list-item[data-tabs-item="' + _tData + '"]').fadeIn(250);
            }, 300);
          }
          return false;
        });
      }

      if ($('.js-text-toggle').length) {
        $('.js-text-toggle').on('click', function () {
          var _t = $(this),
            stateDefault = 'Подробнее о принципах',
            stateActive = 'Свернуть';
          if (window.location.pathname.indexOf('/en/') + 1 > 0) {
            stateDefault = 'Read more',
              stateActive = 'Roll up';
          }
          if (_t.siblings('.section__text-hidden').length) {
            if (_t.hasClass('active')) {
              _t
                .removeClass('active')
                .text(stateDefault);
              _t.siblings('.section__text-hidden').slideUp(350);
            } else {
              _t
                .addClass('active')
                .text(stateActive);
              _t.siblings('.section__text-hidden').slideDown(350);
            }
          }
        })
      }

      $(window).on('resize', function () {
        if ($('.text > table').length && !$('.text > table').parents('.table-wrap').length) {
          $('.text > table').wrap("<div class='table-wrap'></div>");
        }
      });

      $(window).trigger('resize');

    }

  }.init();

});
